import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteararaquara.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3503208',
  schema: 'pbi_dte_araraquara',
  groupId: 'f8d5dadd-00ac-4ace-b8c7-231dcc3521f5',
  analiseGeral: '9fe1493a-2364-4e82-bcb7-13d9f8d675d8',
  analiseUsuarios: 'ff31a260-8c02-4101-885c-47751e2d08b3',
  analiseComunicados: '9a20f434-0605-456d-9c0f-3a53d2da7570',
  analiseProcurador: '889fca48-ab82-4407-8543-788cb3d2eee6',
  analiseEventos: '01f8ac30-e93a-4d34-b150-5fddf75f895f'
};
